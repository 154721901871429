<template>
    <div class="warper">
         <van-pull-refresh class="refresh" v-model="isLoading" @refresh="onRefresh">
           <van-list
            v-model="loading"
            :finished="finished"
            finished-text="我是有底线的"
            :immediate-check="false"
            @load="onLoad"
            :offset="20"
            >
                <ul class="warper-ul">
                    <li v-for="item in tableData" :key="item.id" @click="goInfo(item)">
                        <p>运输批次编号{{item.batch_number}}--{{item.carNumberPlate}}</p>
                        <p>采购类型：{{item.purchaseTypeText}}</p>
                        <p v-if="item.purchaseType!=1">渠道名称：{{item.shopName}}</p>
                        <hr>
                    </li>
                </ul>
            </van-list>
        </van-pull-refresh>
         <van-popup
            v-model="isDiscernShow"
            :closeable="false"
            :close-on-click-overlay="false"
            position="bottom"
            :style="{
                height: '100%',
            }"
        >
            <QualityOk   :infoDta="infoDta" @closepopup="closepopup" />
    </van-popup>
    </div>
</template>

<script>
import { is_post } from "../../../Http/api";
import QualityOk from '../QualityOk.vue'
import { encipherMent, encryptDecode } from '@/utils/encrypt'
    export default {
        components: {
            QualityOk,
        },
        data() {
            return {
                isLoading: false,//下拉刷新
                loading: false, // 上拉加载
                finished: false, // 上拉加载完毕
                isDiscernShow: false, // 上拉加载完毕
                page:1,//页
                pageSize:10,//每页大小
                tableData:[],
                Isstatus:'',
                isComplete:null,
                infoDta:{}
            }
        },
        mounted () {
        },
        methods: {
            goInfo(i){
                if(i.status === 1 ){
                    this.infoDta = i
                    this.isDiscernShow = true
                }else {
                    this.$router.push({path:'/ConfirmPurchasePrice',query:{id:i.id,isComplete:this.isComplete,purchaseType:i.purchaseType}})
                }
            },
            closepopup(){
                this.isDiscernShow = false
            },
             onRefresh() {
                this.finished = false
                this.lodData(10,1,this.Isstatus)
            },
            async lodData(pageSize,page,status) {
                this.Isstatus = status
                let data = encipherMent( JSON.stringify({status,pageSize}))
                    let param = new URLSearchParams();
                    param.append("value",data);
                    param.append("page",page);
                const res = await is_post('index.php/batch/procurementList',param).then(res=>res)
                console.log(res)
                if(res.data.data.length ==0){
                    this.finished = true
                }else{
                    this.finished = false
                    this.isLoading = false
                    this.tableData = res.data.data
                }
            },
            onLoad(){
                let this_ = this
                setTimeout(() => {
                    let page = this_.page + 1
                    this_.page = page
                    let pageSize = this_.pageSize
                    let data = encipherMent( JSON.stringify({pageSize,page,status:this.Isstatus}))
                    let param = new URLSearchParams();
                    param.append("value",data);
                    param.append("page",this_.page);
                    this_.$http.post('index.php/batch/procurementList',param).then(res=>{
                          let result = {}
                        if(res.data.code == -1 || res.data.code == 9){
                            result = res.data
                        }else{
                           result = JSON.parse( encryptDecode(res.data))
                        }
                        if(result.code == 0){
                            let arr = result.data.data
                            arr.forEach(element => {
                                this_.tableData.push(element)
                            });
                            if(this_.tableData.length >= result.data.total){
                                this.finished = true;
                            }else{
                                this.finished = false;
                            }
                            this.loading = false;

                        }
                    })
                   
                }, 1000);
            },
        },
    }
</script>

<style lang="scss" scoped>
.warper-ul{
   padding-top: 50px;
}
</style>