<template>
    <div>
         <div class="isheader">
             <span class="is-span-header go" @click="$router.push('/Quality')"></span>
             <span class="is-span-header">我的采购</span>
             <span class="is-span-header" @click="islogout">退出</span>
        </div>
        <div class="content">
            <van-tabs v-model="activeName" @click="changeHan">
                <van-tab  :name="1" title="待质检">
                    <MyPaging ref="Paging1" />
                </van-tab>
                <van-tab  :name="2" title="待确认价格">
                    <MyPaging ref="Paging2" />
                </van-tab>
                <van-tab  :name="3" title="已确认价格">
                    <MyPaging ref="Paging3"  />
                </van-tab>
            </van-tabs>
        </div>
    </div>
</template>

<script>
import MyPaging from './components/MyPaging.vue'
import { encipherMent, encryptDecode } from '@/utils/encrypt'
    export default {
        components: {
            MyPaging,
        },
        data() {
            return {
                activeName: 1
            }
        },
        mounted () {
            this.changeHan(1);
        },
        methods: {
            changeHan(e) {
                switch (e) {
                    case 1:
                        this.$nextTick(()=>{
                            this.$refs.Paging1.lodData(10,1,1)
                        })
                        break;
                    case 2:
                        this.$nextTick(()=>{
                            this.$refs.Paging2.lodData(10,1,2)
                        })
                        break;
                    case 3:
                        this.$nextTick(()=>{
                            this.$refs.Paging3.lodData(10,1,3)
                            this.$refs.Paging3.isComplete = 1
                        })
                        break;
                
                    default:
                        break;
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
.content{
    margin-top: 50px;
    padding: 10px;
}
/deep/.van-tabs__wrap{
    width: 100%;
    border-bottom: 1px solid #000;
    background-color: #fff;
    position: fixed;
    top: 50px;
    left: 0;
    z-index: 999;
    height: 50px;
}
</style>